import { Avatar, Modal } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { generateInitials } from "../../Common/utils/extraFunctions";
import { documentAction } from "../../DocumentSection/state/actions";
import { EditTwoTone } from "@ant-design/icons";
import { Tooltip } from "antd";

const SignersModal = ({ state, setState, item }) => {
  const dispatch = useDispatch();
  let { clientsArray } = useSelector((state) => state.documentSection.CorpReducer);
  let agentType = item?.isProperty || item?.isErts ? "SELLER_AGENT" : "BUYER_AGENT";
  const isMobile = useMediaQuery({ maxWidth: 470 });

  useEffect(() => {
    if (agentType === "SELLER_AGENT" && state.isSignersModal) {
      dispatch(documentAction.setCorpState({ clientsArray: item?.sellerIds }));
    } else if (agentType === "BUYER_AGENT" && state.isSignersModal) {
      dispatch(documentAction.setCorpState({ clientsArray: item?.buyerIds }));
    }
  }, [item, state.isSignersModal, agentType]);
  
  return (
    <>
      <Modal
        open={state.isSignersModal}
        footer={false}
        onCancel={() => setState({ ...state, isSignersModal: false })}
        title={
          <div style={{ display: "flex", justifyContent: "center", gap: "30px" }}>
            <p style={{ display: "flex", justifyContent: "center", color: "#178DFA", fontWeight: "bold", fontSize: "22px", height: "0px" }}>{agentType === "SELLER_AGENT" ? "Sellers" : "Buyers"}</p>
            {agentType === "SELLER_AGENT" && !item?.persistId && (
              <span onClick={() => setState({ ...state, isUseDetailModal: true })}>
                <Tooltip placement="top" title="Edit Sellers">
                  <EditTwoTone style={{ fontSize: "18px", cursor: "pointer" }} />
                </Tooltip>
              </span>
            )}
          </div>
        }
        style={{ maxHeight: "90%" }}
      >
        <div style={{ overflowY: "auto", marginLeft: isMobile ? "10px" : "50px", marginTop: "-10px" }}>
          {clientsArray
            ?.filter((ele) => !ele.isCorp)
            ?.map((ele, i) => (
              <ul key={i} style={{ marginLeft: "10px" }}>
                <li style={{ fontSize: "20px" }}>{ele?.fullName}</li>
              </ul>
            ))}
          {clientsArray
            ?.filter((ele) => ele.isCorp)
            ?.map((ele, i) => (
              <div key={i} style={{ marginLeft: "10px" }}>
                <ul>
                  <li style={{ fontSize: "20px" }}>
                    {ele?.fullName || ""} {"Signers"}
                  </li>
                </ul>
                {ele.signers?.map((signer, idx) => (
                  <div key={idx} style={{ marginLeft: "30px" }}>
                    <ul>
                      <li style={{ fontSize: "18px" }}>{signer?.fullName}</li>
                    </ul>
                  </div>
                ))}
              </div>
            ))}
        </div>
      </Modal>
    </>
  );
};

export { SignersModal };
