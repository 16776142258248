import axios from "axios";
import dotEnv from "dotenv";
import dayjs from "dayjs";
dotEnv.config();

const apiURL = process.env.REACT_APP_BASE_URL;

// const createCategoryRequest = (data, categoryType) => {
//   let accessTokan = localStorage.getItem("auth");
//   const params = new URLSearchParams();
//   params.append("name", data.name);
//   params.append("categoryType", categoryType);
//   if (data.categoryId) {
//     params.append("categoryId", data.categoryId);
//   }
//   if (data.subCategoryId) {
//     params.append("subCategoryId", data.subCategoryId);
//   }
//   return axios.post(`${apiURL}/category`, params, {
//     headers: {
//       "Content-Type": "application/x-www-form-urlencoded",
//       accept: "application/json",
//       authorization: `bearer ${accessTokan}`,
//     },
//   });
// };

// post event
const postEventRequest = (data, id) => {
  let accessTokan = localStorage.getItem("userAuth");
  const params = new URLSearchParams();
  params.append("personId", id);
  params.append("timestamp", dayjs(data.eventDate).valueOf());
  params.append("name", data.eventName);

  return axios.post(`${apiURL}/events`, params, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      accept: "application/json",
      authorization: `bearer ${accessTokan}`,
    },
  });
};

// Update Address
const updateAddressRequest = (values, data, personId) => {
  let accessTokan = localStorage.getItem("userAuth");
  const params = new URLSearchParams();
  params.append("ownerId", personId);
  params.append("streetNumber", values.streetNumber);
  params.append("unit", values.unit);
  params.append("streetName", values.streetName);
  params.append("city", values.city);
  params.append("state", values.state);
  params.append("zipCode", values.zipCode);

  if (Object.keys(data).length > 0)
    return axios.put(`${apiURL}/address/${data._id}`, params, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        accept: "application/json",
        authorization: `bearer ${accessTokan}`,
      },
    });
  else
    return axios.post(`${apiURL}/address`, params, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        accept: "application/json",
        authorization: `bearer ${accessTokan}`,
      },
    });
};

// Update PHONE
const updatePhoneRequest = (values, data, personId, source) => {
  let accessTokan = localStorage.getItem("userAuth");
  // const params = new URLSearchParams();
  let urlData = {};
  urlData.personId = personId;
  // params.append("ownerId", personId);
  if (source==='officePhoneNumber') {
    // params.append("areaCode", values.officePhoneNumber.substring(0, 3));
    // const officePhoneNumberObj = {"phoneNumber": values.officePhoneNumber};
    // params.append("officePhoneNumber", JSON.stringify(officePhoneNumberObj));
    urlData.officePhoneNumber = {phoneNumber:  values.officePhoneNumber}
  }else if(source === "ACCOUNT"){
    urlData.mobilePhoneNumber = {phoneNumber:  values.phoneNumber,
      countryCode : "+1"
    }
  }
  else {
    // params.append("areaCode", values.phoneNumber.substring(0, 3));
    // const mobilePhoneNumberObj = {"phoneNumber": values.phoneNumber};
    // params.append("mobilePhoneNumber", JSON.stringify(mobilePhoneNumberObj));
    urlData.mobilePhoneNumber = {phoneNumber:  values.phoneNumber}
  }
  // params.append("countryCode", "+1");

  if(source === "ACCOUNT"){
    return axios.put(`${apiURL}/person/profileUpdate`, {...urlData}, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
        authorization: `bearer ${accessTokan}`,
      },
    });
  }

  if (data.mobilePhoneNumber && data.mobilePhoneNumber.phoneNumber > 0) {
    return axios.put(`${apiURL}/person/profileUpdateOfCustomerByRealtor`, {...urlData}, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
        authorization: `bearer ${accessTokan}`,
      },
    });
  } else if (data.officePhoneNumber && data.officePhoneNumber.phoneNumber > 0){
    return axios.put(`${apiURL}/person/profileUpdateOfCustomerByRealtor}`, {...urlData}, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
        authorization: `bearer ${accessTokan}`,
      },
    });
  }
  else {
    return axios.put(`${apiURL}/person/profileUpdateOfCustomerByRealtor`, {...urlData}, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
        authorization: `bearer ${accessTokan}`,
      },
    });
  }
};

// Update email
const updateEmailRequest = (values, data, personId) => {
  let accessTokan = localStorage.getItem("userAuth");
  const params = new URLSearchParams();
  params.append("personId", personId);
  params.append("email", values.email);

  return axios.put(
    `${apiURL}/person/profileUpdateOfCustomerByRealtor`,
    params,
    {
      headers: {
        // "Content-Type": "application/x-www-form-urlencoded",
        accept: "application/json",
        authorization: `bearer ${accessTokan}`,
      },
    }
  );
};

// Update Title
const updateTitleRequest = (values, data, personId) => {
  let accessTokan = localStorage.getItem("userAuth");
  const params = new URLSearchParams();
  params.append("personId", personId);
  params.append("designation", values.designation);

  return axios.put(
    `${apiURL}/person/profileUpdateOfCustomerByRealtor`,
    params,
    {
      headers: {
        // "Content-Type": "application/x-www-form-urlencoded",
        accept: "application/json",
        authorization: `bearer ${accessTokan}`,
      },
    }
  );
};

// Update notes
const updateNotesRequest = (values, data, personId) => {
  let accessTokan = localStorage.getItem("userAuth");
  const params = new URLSearchParams();
  params.append("personId", personId);
  params.append("notes", values.notes);

  return axios.put(
    `${apiURL}/person/profileUpdateOfCustomerByRealtor`,
    params,
    {
      headers: {
        // "Content-Type": "application/x-www-form-urlencoded",
        accept: "application/json",
        authorization: `bearer ${accessTokan}`,
      },
    }
  );
};

// Update notes
const updateBirthdayRequest = (values, data, personId) => {
  let accessTokan = localStorage.getItem("userAuth");
  const params = new URLSearchParams();
  params.append("personId", personId);
  params.append("birthDate", values);

  return axios.put(
    `${apiURL}/person/profileUpdateOfCustomerByRealtor`,
    params,
    {
      headers: {
        // "Content-Type": "application/x-www-form-urlencoded",
        accept: "application/json",
        authorization: `bearer ${accessTokan}`,
      },
    }
  );
};

// Edit event
const editEventRequest = (data, event) => {
  let accessTokan = localStorage.getItem("userAuth");
  const params = new URLSearchParams();
  params.append("personId", event.personId);
  params.append("timestamp", dayjs(data.eventDate).valueOf());
  params.append("name", data.eventName);

  return axios.put(`${apiURL}/events/${event._id}`, params, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      accept: "application/json",
      authorization: `bearer ${accessTokan}`,
    },
  });
};

// Edit NAME
const updateNameRequest = (data, event) => {
  let accessTokan = localStorage.getItem("userAuth");
  const params = new URLSearchParams();
  params.append("firstName", data.firstName);
  params.append("middleName", data.middleName);
  params.append("lastName", data.lastName);
  params.append("personId", event);

  return axios.put(
    `${apiURL}/person/profileUpdateOfCustomerByRealtor`,
    params,
    {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        accept: "application/json",
        authorization: `bearer ${accessTokan}`,
      },
    }
  );
};

///roleHashTag


const updateroleHashtagRequest = (data, personId) => {
  let accessTokan = localStorage.getItem("userAuth");
  const payload = {
    roleHashTags: data,
    personId: personId,
  };
  return axios.put(
    `${apiURL}/person/profileUpdateOfCustomerByRealtor`,
    payload,
    {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
        authorization: `bearer ${accessTokan}`,
      },
    }
  );
};



// delete event
const deleteEventRequest = (id) => {
  let accessTokan = localStorage.getItem("userAuth");
  return axios.delete(`${apiURL}/events/${id}`, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      accept: "application/json",
      authorization: `bearer ${accessTokan}`,
    },
  });
};

const postImageRequest = (data) => {
  let accessTokan = localStorage.getItem("userAuth");
  // const params = new URLSearchParams();
  var params = new FormData();
  params.append("fileBase64", data);
  params.append("folderOf", "MEMORIES");
  // params.append("fileType", "IMAGE");

  return axios.post(`${apiURL}/common/uploadFile`, params, {
    headers: {
      // "Content-Type": "application/x-www-form-urlencoded",
      "Content-Type": "multipart/form-data",
      accept: "application/json",
      authorization: `bearer ${accessTokan}`,
    },
  });
};

// profile image
const uploadImageRequest = (event) => {
  let accessTokan = localStorage.getItem("userAuth");
  var formData = new FormData();
  formData.append("fileBase64", event.upload.file.originFileObj);
  formData.append("folderOf", "PROFILE_PICTURES");

  const config = {
    method: "POST",
    headers: {
      accept: "application/json",
      "Content-Type": "multipart/form-data",
      authorization: `bearer ${accessTokan}`,
    },
    data: formData,
  };
  // return axios(`${apiURL}/common/uploadFile`, config);
  return axios(`${apiURL}/common/uploadFile`, config);
};

const savePdfDocumentForClientApi = (url, id, documentaryId) => {
  let accessTokan = localStorage.getItem("userAuth");
  var formData = new FormData();
  formData.append("url", url);
  formData.append("customerId", id);
  formData.append("documentaryId", documentaryId);

  const config = {
    method: "POST",
    headers: {
      accept: "application/json",
      "Content-Type": "multipart/form-data",
      authorization: `bearer ${accessTokan}`,
    },
    data: formData,
  };
  return axios(`${apiURL}/offerDocumentAttachment`, config);

  // return axios.post(`${apiURL}/offerDocumentAttachment`, formData, {
  //   headers: {
  //     "Content-Type": "multipart/form-data",
  //     accept: "application/json",
  //     authorization: `bearer ${accessTokan}`,
  //   },
  // });
};

const uploadPdfRequest = (event) => {
  let accessTokan = localStorage.getItem("userAuth");
  var formData = new FormData();
  formData.append("fileBase64", event.upload.file.originFileObj);
  formData.append("folderOf", "CLIENT_DOCUMENTS_PDF");

  const config = {
    method: "POST",
    headers: {
      accept: "application/json",
      "Content-Type": "multipart/form-data",
      authorization: `bearer ${accessTokan}`,
    },
    data: formData,
  };
  return axios(`${apiURL}/common/uploadFile`, config);
};

// profile Image post method
const ProfileImageUrlRequest = (element, event, name, section, sourcePlace) => {
  let accessTokan = localStorage.getItem("userAuth");
  let data;
  if (sourcePlace === "COVER_IMAGE") {
    data = {
      coverImg: {
        original: element,
        thumbnail: "",
      },
    };
  } else {
    data = {
      profileImg: {
        original: element,
        thumbnail: "",
      },
    };
  }
  let customeAPI =
    section === "CLIENT"
      ? `${apiURL}/person/profileUpdateOfCustomerByRealtor`
      : `${apiURL}/person/profileUpdate`;

  return axios.put(
    customeAPI,
    { ...(section === "CLIENT" && { personId: event }), ...data },
    {
      headers: {
        accept: "application/json",
        authorization: `bearer ${accessTokan}`,
      },
    }
  );
};

const getClientRequest = ({value, section, page, limit, selectedSortBy, selectedSortOrder, selectedRoles, personId, delegateOwnerId}) => {
  let accessTokan = localStorage.getItem("userAuth");
  return axios.get(`${apiURL}/realtorCustomerList`, {
    params: {
      ...(value ? {search: value} : {}),
      ...(selectedSortBy ? {sortBy: selectedSortBy}: {}),
      ...(selectedSortOrder ? {order: selectedSortOrder}: {}),
      ...(selectedRoles?.length ? {roleHashTags: selectedRoles?.join('&&')}: {}),
      ...(delegateOwnerId ? {delegateOwnerId} : {}),
      ...(personId ? {personId} : {}),
      section: section,
      page: page,
      limit: limit
    },
    headers: {
      // "Content-Type": "application/x-www-form-urlencoded",
      accept: "application/json",
      authorization: `bearer ${accessTokan}`,
    },
  });
};

const getPersonDetailsRequest = ({clientId, source}) => {
  let accessTokan = localStorage.getItem("userAuth");
  return axios.get(`${apiURL}/personDetailedData/${clientId}`, {
    cancelToken: source.token,
    headers: {
      // "Content-Type": "application/x-www-form-urlencoded",
      accept: "application/json",
      authorization: `bearer ${accessTokan}`,
    },
  });
};

// get memories
const getMemoriesDetailsRequest = ({id, source}) => {
  let accessTokan = localStorage.getItem("userAuth");
  return axios.get(`${apiURL}/memories`, {
    cancelToken: source.token,
    params: {
      createdWith: id,
    },
    headers: {
      // "Content-Type": "application/x-www-form-urlencoded",
      accept: "application/json",
      authorization: `bearer ${accessTokan}`,
    },
  });
};

const getCurrentClientRequest = ({customerId, statusType, source}) => {
  let accessTokan = localStorage.getItem("userAuth");
  return axios.get(`${apiURL}/customerOfferList`, {
    cancelToken: source.token,
    params: {
      skip: 0,
      limit: 50,
      statusType: statusType,
      customerId: customerId,
    },
    headers: {
      // "Content-Type": "application/x-www-form-urlencoded",
      accept: "application/json",
      authorization: `bearer ${accessTokan}`,
    },
  });
};

const getDocumentryListRequest = (listType, offerId) => {
  let accessTokan = localStorage.getItem("userAuth");
  return axios.get(`${apiURL}/nestedContractDocumentaries`, {
    params: {
      listType: listType,
      offerId: offerId,
    },
    headers: {
      // "Content-Type": "application/x-www-form-urlencoded",
      accept: "application/json",
      authorization: `bearer ${accessTokan}`,
    },
  });
};

// Get person documents section request
const getPersonDocsRequest = ({id, source}) => {
  let accessTokan = localStorage.getItem("userAuth");
  return axios.get(`${apiURL}/docsContractDocumentaries`, {
    cancelToken: source.token,
    params: {
      customerId: id,
    },
    headers: {
      // "Content-Type": "application/x-www-form-urlencoded",
      accept: "application/json",
      authorization: `bearer ${accessTokan}`,
    },
  });
};

// const getSubCategoryRequest = (categoryId) => {
//   let accessTokan = localStorage.getItem("auth");

//   return axios.get(`${apiURL}/subCategory/${categoryId}`, {
//     headers: {
//       // "Content-Type": "application/x-www-form-urlencoded",
//       accept: "application/json",
//       authorization: `bearer ${accessTokan}`,
//     },
//   });
// };

// const getDockTypeRequest = (subCategoryId) => {
//   let accessTokan = localStorage.getItem("auth");
//   return axios.get(`${apiURL}/docCategory/${subCategoryId}`, {
//     headers: {
//       // "Content-Type": "application/x-www-form-urlencoded",
//       accept: "application/json",
//       authorization: `bearer ${accessTokan}`,
//     },
//   });
// };

// const editCategoryRequest = (data, id) => {
//   let accessTokan = localStorage.getItem("auth");
//   const params = new URLSearchParams();
//   params.append("name", data.name);
//   return axios.put(`${apiURL}/category/${id}`, params, {
//     headers: {
//       "Content-Type": "application/x-www-form-urlencoded",
//       accept: "application/json",
//       authorization: `bearer ${accessTokan}`,
//     },
//   });
// };

// Post Memories
const UpdateImageUrlRequest = (imageUrl, id, name) => {
  let accessTokan = localStorage.getItem("userAuth");
  const params = new URLSearchParams();
  params.append("name", name);
  params.append("imageUrl", imageUrl);
  params.append("createdWith", id);
  return axios.post(`${apiURL}/memories`, params, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      accept: "application/json",
      authorization: `bearer ${accessTokan}`,
    },
  });
};

// Profile Image upload
const uploadImageforProfileRequest = (data, origin) => {
  let accessTokan = localStorage.getItem("userAuth");
  if (origin === "base64") {
    return axios.post(
      `${apiURL}/common/uploadDocsBase64`,
      new URLSearchParams({
        fileBase64: data,
        fileType: "IMAGE",
        folderOf: "PROPERTY_DOCS_IMAGE",
      }),
      {
        headers: {
          accept: "application/json",
          authorization: `bearer ${accessTokan}`,
        },
      }
    );
  }

  var params = new FormData();
  params.append("fileBase64", data);
  params.append("folderOf", "BUGS");

  return axios.post(`${apiURL}/memories`, params, {
    headers: {
      "Content-Type": "multipart/form-data",
      accept: "application/json",
      authorization: `bearer ${accessTokan}`,
    },
  });
};

const deleteMemoriesRequest = (id) => {
  let accessTokan = localStorage.getItem("userAuth");
  return axios.delete(`${apiURL}/memories/${id}`, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      accept: "application/json",
      authorization: `bearer ${accessTokan}`,
    },
  });
};

// drafts api

const draftDocumentsApi = ({data, source}) => {
  let accessTokan = localStorage.getItem("userAuth");
  return axios.get(
    `${apiURL}/offers?clientId=${data.clientId}&status=${data.status}`,
    {
      cancelToken: source.token,
      headers: {
        accept: "application/json",
        authorization: `bearer ${accessTokan}`,
      },
    }
  );
};

const draftDocumentUrlShortning = (data) => {
  let accessTokan = localStorage.getItem("userAuth");
  return axios.post(
    `${apiURL}/url/builtForSection`,
    {
      ...data,
    },
    {
      headers: {
        accept: "application/json",
        authorization: `bearer ${accessTokan}`,
      },
    }
  );
};

const updateNotesApi = (payload) => {
  let formData = new FormData();
  // formData. append('name', 'ABC'); //append the values with key, value pair.
  formData.append("notes", payload.notes);
  let notes = payload.notes;
  let accessTokan = localStorage.getItem("userAuth");
  return axios.put(
    `${apiURL}/memories/${payload.id}`,
    {
      notes,
    },
    {
      headers: {
        // "Content-Type": "multipart/form-data",
        accept: "application/json",
        authorization: `bearer ${accessTokan}`,
      },
    }
  );
};

const getFilterClientRequest = (query) => {
    let apiUrl = `${apiURL}/realtorCustomerList`;
    const queryParams = [];
    if (query.selectedRoles && query.selectedRoles.length > 0) {
      query.selectedRoles.forEach((role) => {
        queryParams.push(`roleHashTags=${role}`);
      });
    }
    if (query.selectedSortBy) {
      queryParams.push(`sortBy=${query.selectedSortBy}`);
    }
    if (query.selectedSortOrder) {
      queryParams.push(`order=${query.selectedSortOrder}`);
    }
    if (queryParams.length > 0) {
      apiUrl += "?" + queryParams.join("&");
    }
    let accessTokan = localStorage.getItem("userAuth");
    return axios.get(apiUrl, {
      headers: {
        accept: "application/json",
        authorization: `bearer ${accessTokan}`,
      },
    });

  };


const getSearchClientRequest = (query, section, page, limit) => {
    let accessTokan = localStorage.getItem("userAuth");
    return axios.get(`${apiURL}/realtorCustomerList`, {
      params: {
        search: query,
        section,
        page,
        limit
      },
      headers: {
        // "Content-Type": "application/x-www-form-urlencoded",
        accept: "application/json",
        authorization: `bearer ${accessTokan}`,
      },
    });
  };

export const clientDataApi = {
  getClientRequest,
  getPersonDetailsRequest,
  getCurrentClientRequest,
  getMemoriesDetailsRequest,
  getDocumentryListRequest,
  postImageRequest,
  postEventRequest,
  deleteEventRequest,
  editEventRequest,
  deleteMemoriesRequest,
  UpdateImageUrlRequest,
  updateNameRequest,
  updateAddressRequest,
  updatePhoneRequest,
  updateEmailRequest,
  updateTitleRequest,
  updateNotesRequest,
  updateBirthdayRequest,
  uploadImageRequest,
  ProfileImageUrlRequest,
  getPersonDocsRequest,
  draftDocumentsApi,
  draftDocumentUrlShortning,
  updateNotesApi,
  uploadImageforProfileRequest,
  uploadPdfRequest,
  savePdfDocumentForClientApi,
  updateroleHashtagRequest,
  getFilterClientRequest,
  getSearchClientRequest,
};
