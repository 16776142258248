import React, { useState } from "react";
import { Form, Upload, message, Button, Typography, Image, Modal } from "antd";
import {LoadingOutlined} from "@ant-design/icons";
import { BiImageAdd } from "react-icons/bi";
const { Text } = Typography;

function AvatarImage({ id, UploadImageForNotes, EditImageOFNotes }) {

  const [fileText, setFileText] = useState({ name: null, size: null });
  const [fileData, setFileData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [spinning, setSpinning] = useState(false);
  const [previewUrl, setPreviewUrl] = useState(null);
  const [visible, setVisible] = useState(false);

  const onFinish = () => {
    setVisible(false);
    if (!id) {
      UploadImageForNotes(fileData);
    } else {
      EditImageOFNotes(fileData);
    }
  };

  function beforeUpload(file) {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt5M = file.size / 1024 / 1024 < 5;
    if (!isLt5M) {
      message.error("Image must smaller than 5MB!");
    }
    return isJpgOrPng && isLt5M;
    // if (supportedFormats.includes("image")) {
    //   const isJpgOrPng =
    //     file.type === "image/jpeg" || file.type === "image/png";
    //   if (!isJpgOrPng) {
    //     message.error("You can only upload JPG/PNG file!");
    //   }
    //   const isLt2M = file.size / 1024 / 1024 < 2;
    //   if (!isLt2M) {
    //     message.error("Image must be smaller than 2MB!");
    //   }
    //   setLoading(false)
    //   return isJpgOrPng && isLt2M;
    // }

    // if (supportedFormats.includes("pdf")) {
    //   const isPdf = file.type === "application/pdf";
    //   if (!isPdf) {
    //     message.error("You can only upload PDF file!");
    //   }
    //   const isLt2M = file.size / 1024 / 1024 < 2;
    //   if (!isLt2M) {
    //     message.error("File must be smaller than 2MB!");
    //   }
    //   return isPdf && isLt2M;
    // }

    // const isJpgOrPng = file.type === "application/png";

    // if (!isJpgOrPng) {
    //   message.error("You can only upload PDF file!");
    // }
    // const isLt2M = file.size / 1024 / 1024 < 5;
    // if (!isLt2M) {
    //   message.error("PDF must smaller than 5MB!");
    // }
    // return isJpgOrPng && isLt2M;
  }

  function bytesToSize(bytes) {
    let sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    if (bytes === 0) return "0 Byte";
    let i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
  }

  let handleCustome = (event) => {
    setFileData(event.file);
    setVisible(true);
    // Setting name to show the name of image
    setFileText({ name: event.file.name, size: bytesToSize(event.file.size) });
    setLoading(true);
    setSpinning(true);
    // Preview the image before uploading to our server
    const file = event.file;
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const base64String = reader.result
        .replace("data:", "")
        .replace(/^.+,/, "");
      setPreviewUrl(base64String);
      setSpinning(false);
    };
  };

  const HandleCancel = () => {
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <Form onFinish={onFinish} autoComplete="off">
      <div
        style={{
          height: "12px",
          marginTop: "-25px",
        }}
      >
        <Form.Item
          name="upload"
          // label="Upload File"
          rules={[{ required: true, message: "Please select file!" }]}
        >
          <Upload
            name="avatar"
            listType="picture-circle"
            className="avatar-uploader"
            showUploadList={false}
            // accept="application/png"
            // showUploadList={true}
            customRequest={handleCustome}
            beforeUpload={beforeUpload}
            style={{ fontSize: "20px" }}
            size={10}
          >
            <span
              style={{
                cursor: "pointer",
                height: "10px",
              }}
            >
              {!id ? (
                <BiImageAdd
                  style={{
                    fontSize: "34px",
                    fill: "#085191",
                    color: "#085191",
                  }}
                />
              ) : (
                <Button
                  type="primary"
                  style={{ padding: "0 25px" }}
                  onClick={() => setVisible(false)}
                >
                  Update
                </Button>
              )}
            </span>
          </Upload>
        </Form.Item>
      </div>

      {/* Modal opens when someone select an image */}
      <Modal visible={visible} onCancel={handleCancel} footer={[]}>
        <Form.Item>
          {/* Show spinning when photo is dropped */}
          <div
            style={{
              width: "10%",
              margin: "auto",
              display: spinning ? "flex" : "none",
            }}
          >
            <LoadingOutlined
              style={{
                fontSize: 64,
              }}
              spin
            />
          </div>

          {/* Show only when New pic got uploaded */}

          <div
            style={{
              display: previewUrl ? "flex" : "none",
              width: "40%",
              margin: "auto",
            }}
          >
            {previewUrl && (
              <Image
                src={`data:image/png;base64,${previewUrl}`}
                width="250px"
                height="200px"
              />
            )}
          </div>
        </Form.Item>
        {/* Showing Name of the Image */}
        {fileText.name && (
          <Form.Item label="File Name ">
            <Text type="secondary">{fileText.name}</Text>
            <br />
            <Text type="secondary">Size: {fileText.size}</Text>
          </Form.Item>
        )}
        {/* Buttons to save or reject the image */}
        <Form.Item
          style={{ marginTop: "-10px" }}
          wrapperCol={{ offset: 10, span: 14 }}
        >
          <Button
            type="primary"
            // htmlType="submit"
            onClick={onFinish}
            style={{ marginRight: "15px", marginLeft: "-50px" }}
          >
            Save
          </Button>
          {/*  */}
          <Button type="primary" onClick={HandleCancel}>
            Cancel
          </Button>
        </Form.Item>
      </Modal>
    </Form>
  );
}
export default AvatarImage;
